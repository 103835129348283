<div class="row">
    <div class="card shadow mb-4 col-lg-12">
        <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Flight details</h6>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-xl-3 col-md-3 mb-3">
                    <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Departure</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{pirep.departure}}
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-plane-departure fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-3 mb-3">
                    <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Arrival</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{pirep.arrival}}
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-plane-arrival fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-3 mb-3">
                    <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Start time</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{pirep.startTime}}
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-calendar-day fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-3 mb-3">
                    <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        End time</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{pirep.endTime}}
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-calendar-day fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-3 col-md-3 mb-3">
                    <div class="card border-left-success shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Flight time</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{pirep.length}}
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-clock fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-3 mb-3">
                    <div class="card border-left-success shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Aircraft</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{pirep.aircraft}} ({{pirep.landingRate | number:'1.0-0'}} ft/min)
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-plane fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-3 mb-3">
                    <div class="card border-left-success shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Pilot</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{pirep.user}}
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-user fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-3 mb-3">
                    <div class="card border-left-success shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Info</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{pirep.info}}
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-archive fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-3 col-md-3 mb-3">
                    <div class="card border-left-warning shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Fuel start</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{pirep.fuelStart | number :
                                        '1.2-2' | removeComma}} kg
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-gas-pump fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-3 mb-3">
                    <div class="card border-left-warning shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Fuel end</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{pirep.fuelEnd | number :
                                        '1.2-2' | removeComma}} kg
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-gas-pump fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-3 mb-3">
                    <div class="card border-left-warning shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Initial fuel</div>
                                    <div *ngIf="pirep.fuelBought > 0" class="h5 mb-0 font-weight-bold text-gray-800">{{pirep.fuelStart - pirep.fuelBought | number :
                                        '1.2-2' | removeComma}} kg
                                    </div>
                                    <div *ngIf="pirep.fuelBought == 0" class="h5 mb-0 font-weight-bold text-gray-800">0 kg
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-gas-pump fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-3 mb-3">
                    <div class="card border-left-warning shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Fuel bought</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{pirep.fuelBought | number :
                                        '1.2-2' | removeComma}} kg
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-gas-pump fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-3 col-md-3 mb-3">
                    <div class="card border-left-primary shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Fuel spent</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{pirep.fuelSpent | number :
                                        '1.2-2' | removeComma}} kg
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-gas-pump fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-3 mb-3">
                    <div class="card border-left-primary shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Fuel cost</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">${{pirep.fuelCost}}
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-receipt fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-3 mb-3">
                    <div class="card border-left-primary shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Earning</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">${{pirep.earning}}
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-3 mb-3">
                    <div class="card border-left-primary shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Profit</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">${{pirep.profit}}
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-money-bill-wave fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="map-holder">
    <div class="container fill">
      <div id="map"></div>
    </div>
  </div>
  


import { Router } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';
import { AircraftService } from './../../../services/aircraft.service';
import { Aircraft } from './../../../models/aircraft.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aircraft-list',
  templateUrl: './aircraft-list.component.html',
  styleUrls: ['./aircraft-list.component.css'],
})
export class AircraftListComponent implements OnInit {
  aircrafts: Aircraft[];
  dtOptions: DataTables.Settings = {};
  fetched: boolean = false;
  systemAdmin: boolean = true;
  form: any = {
    id: null,
    maxCargo: null,
  };

  form1: any = {
    id: null,
    icao: null,
    paxNum: null,
    cargo: false
  }

  constructor(
    private aircraftService: AircraftService,
    private tokenService: TokenService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.systemAdmin = this.tokenService.getUser().roles.includes('ROLE_SYSTEM_ADMIN');
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      lengthMenu: [5, 10, 25],
      processing: true,
      order: [],
    };

    if (this.systemAdmin) {
      this.aircraftService.readAllAircrafts().subscribe((data) => {
        this.aircrafts = data;
        this.fetched = true;
      });
    } else {
      this.aircraftService.readForBuying().subscribe((data) => {
        this.aircrafts = data;
        this.fetched = true;
      });
    }
  }

  onSubmit() {
    this.aircraftService
      .convertToCargo(this.form.id, this.form.maxCargo)
      .subscribe((data) => {
        this.ngOnInit();
      });
  }

  onSubmitBuy() {
    this.aircraftService
      .buy(this.form1.id, this.form1.icao, this.form1.paxNum, this.form1.registration, this.form1.cargo)
      .subscribe((data) => {
        document.getElementById('closeBuyModal').click();
        this.router.navigate(['/airadmin/fleet']);
      });
  }

  convertClick(id: number) {
    this.form.id = id;
  }

  buy(id: number,icao: string, cargo: boolean, max: number) {
    this.form1.id = id;
    this.form1.icao = icao;
    this.form1.cargo = cargo;
    this.form1.paxNum = max;
  }
}

<div class="row">
  <div class="card shadow mb-4 col-lg-12">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary">Aircraft details</h6>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-xl-4 col-md-4 mb-4">
          <div class="card border-left-info shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Type</div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{aircraft.name}}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-plane fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 mb-4">
          <div class="card border-left-info shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Is cargo</div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{aircraft.cargo}}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-box fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 mb-4">
          <div class="card border-left-info shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Registration</div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{aircraft.registration}}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-registered fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-4 col-md-4 mb-4">
          <div class="card border-left-success shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Capacity</div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{aircraft.capacity}}
                  </div>
                </div>
                <div class="col-auto">
                  <i  class="fas fa-users fa-2x text-gray-300" ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 mb-4">
          <div class="card border-left-success shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Location</div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{aircraft.location}}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-map fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 mb-4">
          <div class="card border-left-success shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Fuel on board</div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{aircraft.fuelOnBoard}}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-gas-pump fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <!-- Content Column -->
  <div class="col-lg-12 mb-12">
    <!-- Project Card Example -->
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Flights -- {{aircraft.flights.length}}</h6>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table  *ngIf="fetched" datatable="ng" [dtOptions]="dtOptions" class="table table-bordered">
            <thead>
            <tr>
              <th>Departure</th>
              <th>Arrival</th>
              <th>Start time</th>
              <th>End time</th>
              <th>Aircraft</th>
              <th>Profit</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let flight of aircraft.flights">
              <td>{{flight.departure}}</td>
              <td>{{flight.arrival}}</td>
              <td>{{flight.startTime}}</td>
              <td>{{flight.endTime}}</td>
              <td>{{flight.aircraft}} ({{flight.landingRate | number:'1.0-0'}} ft/min)</td>
              <td>${{flight.profit}}</td>
              <td>
                <a *ngIf="isAdmin" [routerLink]="['/airadmin/pirep', flight.id]"
                   class="btn btn-info btn-circle btn-sm"><i class="fas fa-info-circle"></i></a>
                <a *ngIf="!isAdmin" [routerLink]="['/user/pirep', flight.id]"
                   class="btn btn-info btn-circle btn-sm"><i class="fas fa-info-circle"></i></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

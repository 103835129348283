import { UserService } from './../../../services/user.service';
import { Router } from '@angular/router';
import { AircraftService } from './../../../services/aircraft.service';
import { AirlineAircraft } from './../../../models/airline-aircraft.model';
import { TokenService } from './../../../services/token.service';
import { RouteService } from './../../../services/route.service';
import { Route } from './../../../models/route.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-book-flight',
  templateUrl: './book-flight.component.html',
  styleUrls: ['./book-flight.component.css'],
})
export class BookFlightComponent implements OnInit {
  routes: Route[];
  aircrafts: AirlineAircraft[];
  searchText = '';
  location: string;
  canBook: boolean = false;
  form: any = {
    aircraft: null,
  };
  selectedRoute: number;

  constructor(
    private routeService: RouteService,
    private tokenService: TokenService,
    private aircraftService: AircraftService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userService.readLocation().subscribe((data) => {
      this.location = data;
    });
    this.routeService.readRoutesForLocation().subscribe((data) => {
      this.routes = data;
    });
    this.aircraftService.readAirlineAircraftForLocation().subscribe((data) => {
      this.aircrafts = data;
    });
    this.routeService.canBook().subscribe((data) => {
      this.canBook = data;
    });
  }

  routeSelected(id: number) {
    this.selectedRoute = id;
  }

  onSubmit() {
    this.routeService
      .bookFlight(this.selectedRoute, this.form.aircraft)
      .subscribe((data) => {
        this.tokenService.setBookedFlight();
        document.getElementById('closeModalButton').click();
        if (this.tokenService.getUser().roles.includes('ROLE_ADMIN')) {
          this.router.navigate(['/airadmin/dispatch']);
        } else {
          this.router.navigate(['/user/dispatch']);
        }
      });
  }
}

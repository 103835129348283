import { Register } from './../../../models/register.model';
import { AuthService } from './../../../services/auth.service';
import { Router } from '@angular/router';
import { UserService } from './../../../services/user.service';
import { AirlineService } from './../../../services/airline.service';
import { Component, OnInit } from '@angular/core';
import { Airline } from 'src/app/models/airline.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  createAirline: boolean = false;
  form: any = {
    username: null,
    email: null,
    fullName: null,
    password: null,
    airlineName: null,
    airlineIcao: null,
    airlineBaseIcao: null,
    startingBalance: 1000000000,
    airlineId: null
  };

  airlines: Airline[];

  constructor(private airlineService: AirlineService, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.airlineService.readAirlines().subscribe((data) => {
      this.airlines = data;
    });
  }

  onSubmit() {
    let register = new Register(
      this.form.username,
      this.form.email,
      this.form.fullName,
      this.form.password,
      this.createAirline ? this.form.airlineName : null,
      this.createAirline ? this.form.airlineIcao : null,
      this.createAirline ? this.form.airlineBaseIcao : null,
      this.createAirline ? this.form.startingBalance : null,
      !this.createAirline ? this.form.airlineId : null
    );
    this.authService.register(register).subscribe((data) => {
      this.router.navigate(['/login']);
    });
  }

}

<!-- DataTales Example -->
<div class="card shadow mb-4">
    <div class="card-header py-3  d-flex justify-content-between align-items-center">
        <h6 class="m-0 font-weight-bold text-primary">All flights</h6>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table *ngIf="fetched" datatable="ng" [dtOptions]="dtOptions" class="table table-bordered table-hover"
                width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>Departure</th>
                        <th>Arrival</th>
                        <th>Departure time</th>
                        <th>Arrival time</th>
                        <th>Length</th>
                        <th>Aircraft</th>
                        <th>User</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let flight of flights" [ngClass]="{'text-info font-weight-bold': flight.user == user}">
                        <td>{{flight.departure}}</td>
                        <td>{{flight.arrival}}</td>
                        <td>{{flight.startTime}}</td>
                        <td>{{flight.endTime}}</td>
                        <td>{{flight.length}}</td>
                        <td>{{flight.aircraft}} ({{flight.landingRate | number:'1.0-0'}} ft/min)</td>
                        <td>{{flight.user}}</td>
                        <td>
                            <a *ngIf="isAdmin" [routerLink]="['/airadmin/pirep', flight.id]"
                                class="btn btn-info btn-circle btn-sm"><i class="fas fa-info-circle"></i></a>
                            <a *ngIf="!isAdmin" [routerLink]="['/user/pirep', flight.id]"
                                class="btn btn-info btn-circle btn-sm"><i class="fas fa-info-circle"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<!-- Page Heading -->
<div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">{{airline.name}} {{airline.icao}} - Dashboard</h1>

</div>

<!-- Content Row -->
<div class="row">

    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-primary shadow h-100 py-2">
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            Current balance</div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">${{airline.balance | number}}</div>
                    </div>
                    <div class="col-auto">
                        <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-success shadow h-100 py-2">
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                            Pilot number</div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{airline.numberOfPilots}}</div>
                    </div>
                    <div class="col-auto">
                        <i class="fas fa-users fa-2x text-gray-300"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-warning shadow h-100 py-2">
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                            Number of flights</div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{airline.numberOfFlights}}</div>
                    </div>
                    <div class="col-auto">
                        <i class="fas fa-plane-departure fa-2x text-gray-300"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-info shadow h-100 py-2">
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                            Number of planes</div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{airline.numberOfPlanes}}</div>
                    </div>
                    <div class="col-auto">
                        <i class="fas fa-plane fa-2x text-gray-300"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
  <div class="col-md-6 offset-3">
    <h4>Favorite airport pair: <b>{{statisticsPer.favoritePair}}</b></h4>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <app-pie *ngIf="loaded" [data]="statisticsPer.perUser" title="Flights per user"></app-pie>
  </div>
  <div class="col-md-6">
    <app-pie *ngIf="loaded" [data]="statisticsPer.perAircraftType" title="Flights per aircraft type"></app-pie>
  </div>
</div>

<div class="row">
     <!-- Content Column -->
     <div class="col-lg-6 mb-4">
        <!-- Project Card Example -->
        <div class="card shadow mb-4">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Last 5 flights</h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Departure</th>
                                <th>Arrival</th>
                                <th>Start time</th>
                                <th>End time</th>
                                <th>Aircraft</th>
                                <th>User</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let flight of airline.lastFiveFlights">
                                <td>{{flight.departure}}</td>
                                <td>{{flight.arrival}}</td>
                                <td>{{flight.startTime}}</td>
                                <td>{{flight.endTime}}</td>
                                <td data-bs-toggle="tooltip" data-bs-placement="top" title="Landing rate {{flight.landingRate | number:'1.0-0'}} ft/min">{{flight.aircraft}} </td>
                                <td>{{flight.user}}</td>
                                <td>
                                    <a [routerLink]="['/airadmin/pirep', flight.id]" class="btn btn-info btn-circle btn-sm"><i
                                        class="fas fa-info-circle"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- Content Column -->
    <div class="col-lg-6 mb-4">
        <!-- Project Card Example -->
        <div class="card shadow mb-4">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Bookings</h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Departure</th>
                                <th>Arrival</th>
                                <th>Flight number</th>
                                <th>Aircraft</th>
                                <th>Special cargo</th>
                                <th>User</th>
                                <th>User</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let booking of airline.bookings">
                                <td>{{booking.departure}}</td>
                                <td>{{booking.arrival}}</td>
                                <td>{{booking.flightNumber}}</td>
                                <td>{{booking.aircraft}}</td>
                                <td>{{booking.specialCargo}}</td>
                                <td>{{booking.user}}</td>
                                <td> <button (click)="cancelBooking(booking.user)" class="btn btn-warning btn-circle btn-sm"><i class="fas fa-trash"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

import { TokenService } from 'src/app/services/token.service';
import { Component, OnInit } from '@angular/core';
import { Flight } from 'src/app/models/flight.model';
import { FlightService } from 'src/app/services/flight.service';

@Component({
  selector: 'app-flight-list',
  templateUrl: './flight-list.component.html',
  styleUrls: ['./flight-list.component.css']
})
export class FlightListComponent implements OnInit {

  flights: Flight[];
  dtOptions: DataTables.Settings = {};
  fetched: boolean = false;
  user: string = null;
  isAdmin = false;


  constructor(private flightService: FlightService, private tokenService: TokenService) {}

  ngOnInit(): void {
    
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      lengthMenu: [5, 10, 25],
      processing: true,
      order: []
    };

    this.user = this.tokenService.getUser().username;

    this.flightService.readAllFlights().subscribe((data) => {
      this.flights = data;
      this.fetched = true;
    });

    if (this.tokenService.getUser().roles.includes('ROLE_ADMIN')) {
      this.isAdmin = true;
    } 

  }

}

import { Route } from './../../../models/route.model';
import { Component, OnInit } from '@angular/core';
import { RouteService } from 'src/app/services/route.service';

@Component({
  selector: 'app-route-list',
  templateUrl: './route-list.component.html',
  styleUrls: ['./route-list.component.css']
})
export class RouteListComponent implements OnInit {

  routes: Route[];
  dtOptions: DataTables.Settings = {};
  fetched: boolean = false;
  form: any = {
    departure: null,
    arrival: null,
    returnFlight: true
  }

  constructor(private routeService: RouteService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      lengthMenu: [5, 10, 25],
      processing: true,
      order: []
    };

    this.loadData();
  }

  loadData() {
    this.routeService.readAllRoutes().subscribe((data) => {
      this.routes = data
      this.fetched = true;
    });
  }

  onSubmit() {
    this.routeService
        .createRoute(
          this.form.departure,
          this.form.arrival,
          this.form.returnFlight
        )
        .subscribe((data) => {
          this.fetched = false;
          this.loadData();
          document.getElementById('closeModalButton').click();
        });
  }

}

import { FlightService } from 'src/app/services/flight.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Flight } from 'src/app/models/flight.model';
import { ActivatedRoute } from '@angular/router';
import * as L from 'leaflet';
@Component({
  selector: 'app-pirep',
  templateUrl: './pirep.component.html',
  styleUrls: ['./pirep.component.css'],
})
export class PirepComponent implements AfterViewInit {
  id: number;
  pirep: Flight;

  private map;

  private initMap(): void {
    const departureIcon = L.divIcon({
      html:
        '<span class="text-success"><i class="fas fa-plane-departure fa-3x"></i></span>',
      iconSize: [20, 20],
      className: 'myDivIcon',
    });
    const arrivallIcon = L.divIcon({
      html:
        '<span class="text-danger"><i class="fas fa-plane-arrival fa-3x"></i></span>',
      iconSize: [20, 20],
      className: 'myDivIcon',
    });
    const deplon = this.pirep.deplon;
    const deplat = this.pirep.deplat;
    const arlon = this.pirep.arlon;
    const arlat = this.pirep.arlat;
    this.map = L.map('map', {
      center: [deplat, deplon],
      zoom: 3,
    }).fitBounds(
      [
        [deplat, deplon],
        [arlat, arlon],
      ],
      { padding: [50, 50] }
    );

   
    const tiles = L.tileLayer(
      'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png',
      {
        maxZoom: 18,
        minZoom: 3,
        attribution:
          '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
      }
    );

    tiles.addTo(this.map);

    const depMarker = L.marker([deplat, deplon], {
      title: this.pirep.departure,
      icon: departureIcon,
    });

    depMarker.addTo(this.map);
    depMarker
      .bindPopup(this.pirep.departure, {
        autoClose: false,
        closeOnClick: false,
      })
      .openPopup();

    const arrMarker = L.marker([arlat, arlon], {
      title: this.pirep.arrival,
      icon: arrivallIcon,
    });

    arrMarker.addTo(this.map);
    arrMarker
      .bindPopup(this.pirep.arrival, { autoClose: false, closeOnClick: false })
      .openPopup();

    var polylinePoints = [
      [deplat, deplon],
      [arlat, arlon],
    ];

    var polyline = L.polyline(polylinePoints).addTo(this.map);
  }

  constructor(
    private flightService: FlightService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.flightService.readFlight(this.id).subscribe((data) => {
      this.pirep = data;
    });
  }
}

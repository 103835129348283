import { AirlineAircraft } from './../models/airline-aircraft.model';
import { Aircraft } from './../models/aircraft.model';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {Flight} from "../models/flight.model";

@Injectable({
  providedIn: 'root',
})
export class AircraftService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_API_URL') private baseUrl: string
  ) {}

  readAllAircrafts() {
    return this.http
      .get(this.baseUrl + '/admin/aircraft/all')
      .pipe(
        map((data: any[]) =>
          data.map((item: any) => this.createAircraftFromObject(item))
        )
      );
  }

  readForBuying() {
    return this.http
      .get(this.baseUrl + '/aircraft/for-buying')
      .pipe(
        map((data: any[]) =>
          data.map((item: any) => this.createAircraftFromObject(item))
        )
      );
  }

  readAirlineAircraftForLocation() {
    return this.http
      .get(this.baseUrl + '/booking/user/booking/aircrafts')
      .pipe(
        map((data: any[]) =>
          data.map((item: any) => this.createAirlineAircraftFromObject(item))
        )
      );
  }

  readAllAirlineAircraft() {
    return this.http
    .get(this.baseUrl + '/airline/user/fleet')
    .pipe(
      map((data: any[]) =>
        data.map((item: any) => this.createAirlineAircraftFromObject(item))
      )
    );
  }

  readAllAirlineAircraftDetails(id: number) {
    return this.http
      .get(this.baseUrl + '/airline/user/fleet/' + id)
      .pipe(
        map((data: any) => this.createAirlineAircraftDetailedFromObject(data))
      );
  }

  convertToCargo(id: number, maxCargo: number) {
    return this.http.post(this.baseUrl + '/admin/aircraft/create', {
      id: id,
      maxCargo: maxCargo,
    });
  }

  changeLocation(id: number, location: string) {
    return this.http.put(this.baseUrl + '/airline/admin/details/plane/' + id + '/' + location.toUpperCase(), null);
  }

  buy(id: number, icao: string, paxNum: number, registration: string, cargo: boolean) {
    let dto = {
      icao: icao,
      registration: registration,
      passengers: paxNum,
      cargo: cargo
    };

    return this.http.post(this.baseUrl + '/aircraft/buy', dto);
  }

  private createAircraftFromObject(item: any) {
    return new Aircraft(
      item.id,
      item.icao,
      item.name,
      item.price,
      item.maxPassengers,
      item.maxCargo,
      item.cargo
    );
  }

  private createAirlineAircraftFromObject(item: any) {
    return new AirlineAircraft(
      item.id,
      item.name,
      item.location,
      item.type,
      item.capacity,
      item.registration,
      item.cargo,
      item.fuelOnBoard
    );
  }

  private createAirlineAircraftDetailedFromObject(item: any) {
    let aircraft = new AirlineAircraft(
      item.id,
      item.name,
      item.location,
      item.type,
      item.capacity,
      item.registration,
      item.cargo,
      item.fuelOnBoard
    );

    aircraft.flights = item.flights.map((data) => this.convertObjectToFlight(data))

    return aircraft;
  }

  private convertObjectToFlight(item: any) {
    return new Flight(
      item.id,
      item.departure,
      item.arrival,
      item.startTime,
      item.endTime,
      item.length,
      item.aircraft,
      item.user,
      item.fuelSpent,
      item.earning,
      item.fuelCost,
      item.profit,
      item.info,
      item.landingRate,
      item.fuelStart,
      item.fuelEnd,
      item.fuelBought,
      item.deplat,
      item.deplon,
      item.arlat,
      item.arlon
    );
  }
}

<!-- Page Heading -->
<div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Routes from <span class="text-success">{{location}}</span></h1>
</div>
<div class="row">
    <div class="col-md-12">
    <div class="form-group">
        <label for="search-text">Search by arrival</label>
        <input type="email" class="form-control" id="search-text" aria-describedby="search-text" 
          [(ngModel)]="searchText" placeholder="Arrival icao" 
          autofocus>
      </div>
    </div>
</div>

<!-- Content Row -->
<div class="row">
    <div class="col-xl-2 col-md-2 mb-2" *ngFor="let route of routes | routeFilter: searchText">
        <div class="card border-left-info shadow h-100 py-2">
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="h4 text-xl font-weight-bold text-success text-uppercase mb-1">
                            {{route.arrival}}</div>
                        <div class="h6 mb-0 font-weight-bold text-gray-800"><span class="text-warning">{{route.flightNumber}}</span> - ${{route.ticketPrice}}</div>
                    </div>
                    <div class="col-auto" *ngIf="canBook && !route.booked">
                        <i (click)="routeSelected(route.id)" data-toggle="modal" data-target="#bookModal" class="fas fa-plane-departure fa-2x text-gray-300" style="cursor: pointer;"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="bookModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                <div class="modal-body">
                    <div class="custom-control custom-switch">
                    </div>
                    <div class="form-group">
                        <label class="text-primary" for="maxCargo">Aircraft</label>
                        <select name="aircraft" class="form-control" [(ngModel)]="form.aircraft" #aircraft="ngModel" required>
                            <option *ngFor="let aircraft of aircrafts" [value]="aircraft.id">
                                {{aircraft.name}} {{aircraft.registration}} ({{aircraft.fuelOnBoard}} kg)
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalButton" type="button" class="btn btn-secondary"
                        data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Book</button>
                </div>
            </form>
        </div>
    </div>
</div>
import { MetarService } from './../../services/metar.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  username: string;
  role: string;
  metar = {raw: ''};
  form: any = {
    icao: null
  };

  constructor(private tokenService: TokenService, private router: Router, private metarService: MetarService) { }

  ngOnInit(): void {
    this.username = this.tokenService.getUser().username;
    this.role = this.tokenService.getUser().roles[0];
  }

  logout() {
    this.tokenService.signOut();
    this.router.navigate(['/login']);
  }

  onSubmit() {
    this.metarService.getMetar(this.form.icao).subscribe(data => {
      this.metar = data;
    })
  }

}

<!-- Page Heading -->
<div class="row">
    <div class="card shadow mb-4 col-lg-12">
        <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Flight details</h6>
        </div>
        <div class="card-body">
            <div *ngIf="booking.departure">
                <div class="row">
                    <div class="col-xl-3 col-md-6 mb-4">
                        <div class="card border-left-info shadow h-100 py-2">
                            <div class="card-body">
                                <div class="row no-gutters align-items-center">
                                    <div class="col mr-2">
                                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Flight number</div>
                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{booking.flightNumber}}
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-ticket-alt fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Earnings (Monthly) Card Example -->
                    <div class="col-xl-3 col-md-6 mb-4">
                        <div class="card border-left-success shadow h-100 py-2">
                            <div class="card-body">
                                <div class="row no-gutters align-items-center">
                                    <div class="col mr-2">
                                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Departure</div>
                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{booking.departure}}</div>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-plane-departure fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Earnings (Monthly) Card Example -->
                    <div class="col-xl-3 col-md-6 mb-4">
                        <div class="card border-left-success shadow h-100 py-2">
                            <div class="card-body">
                                <div class="row no-gutters align-items-center">
                                    <div class="col mr-2">
                                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Arrival</div>
                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{booking.arrival}}</div>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-plane-arrival fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-md-6 mb-4">
                        <div class="card border-left-info shadow h-100 py-2">
                            <div class="card-body">
                                <div class="row no-gutters align-items-center">
                                    <div class="col mr-2">
                                        <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                                            Pilot in command</div>
                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{booking.user}}</div>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-user fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-xl-3 col-md-6 mb-4">
                        <div class="card border-left-info shadow h-100 py-2">
                            <div class="card-body">
                                <div class="row no-gutters align-items-center">
                                    <div class="col mr-2">
                                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Aircraft</div>
                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{booking.aircraft}}</div>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-plane fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Earnings (Monthly) Card Example -->
                    <div class="col-xl-3 col-md-6 mb-4">
                        <div class="card border-left-success shadow h-100 py-2">
                            <div class="card-body">
                                <div class="row no-gutters align-items-center">
                                    <div class="col mr-2">
                                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Pax number</div>
                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{booking.passengers}}</div>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-users fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Earnings (Monthly) Card Example -->
                    <div class="col-xl-3 col-md-6 mb-4">
                        <div class="card border-left-success shadow h-100 py-2">
                            <div class="card-body">
                                <div class="row no-gutters align-items-center">
                                    <div class="col mr-2">
                                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Cargo</div>
                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{booking.cargo}} kg</div>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-boxes fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-md-6 mb-4">
                        <div class="card border-left-info shadow h-100 py-2">
                            <div class="card-body">
                                <div class="row no-gutters align-items-center">
                                    <div class="col mr-2">
                                        <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                                            Special cargo</div>
                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{booking.specialCargo}}
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-box fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <button class="btn btn-warning btn-block" (click)="cancelBooking()">Cancel booking</button>
                    </div>
                </div><br>
                <center><h2>Weather briefing</h2></center>
                <div class="row">
                    <div class="col-md-12">
                        <h5 class="text-info"><b>{{booking.departure}}</b> METAR:</h5> <p>{{depMetar.raw}}</p>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12">
                        <h5 class="text-info"><b>{{booking.arrival}}</b> METAR:</h5> <p>{{arrMetar.raw}}</p>
                    </div>
                </div>
                <iframe *ngIf="loaded" [src]="depUrl" frameBorder="0" width="250" height="380" scrolling="no"></iframe>&nbsp;&nbsp;
                <iframe *ngIf="loaded" [src]="arrUrl" frameBorder="0" width="250" height="380" scrolling="no"></iframe>
            </div>
            <div *ngIf="booking.departure == undefined">
                <center>
                    <h2>You don't have a booked flight</h2>
                </center>
            </div>
        </div>
    </div>
</div>
<div id="map-holder">
    <div class="container fill">
      <div id="map"></div>
    </div>
  </div>

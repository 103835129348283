<!-- DataTales Example -->
<div class="card shadow mb-4">
    <div class="card-header py-3  d-flex justify-content-between align-items-center">
        <h6 class="m-0 font-weight-bold text-primary">Application users</h6>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table *ngIf="fetched" datatable="ng" [dtOptions]="dtOptions" class="table table-bordered table-hover" width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Airline</th>
                        <th>Location</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of users">
                        <td>{{user.userName}}</td>
                        <td>{{user.fullName}}</td>
                        <td><span class="badge badge-info">{{user.email}}</span></td>
                        <td>{{user.airline}}</td>
                        <td>{{user.location}}</td>
                        <td>
                            <a [routerLink]="['/airadmin/profile-info', user.userName]" class="btn btn-info btn-circle btn-sm"><i
                                    class="fas fa-info-circle"></i></a> &nbsp;
                            <button type="button" (click)="deleteUser(user.userName)" class="btn btn-danger btn-circle btn-sm"><i
                                    class="fas fa-trash"></i></button>&nbsp;
                            <button *ngIf="!pilots" (click)="resetPassword(user.userName)" type="button" class="btn btn-warning btn-circle btn-sm"><i
                            class="fas fa-key"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {ThemeOption} from "ngx-echarts";
import {EChartsOption} from "echarts";
import {PieElement} from "../../models/per-user.model";

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.css']
})
export class PieComponent implements OnInit{

  @Input()
  data: PieElement[] = []

  @Input()
  title: string = ''

  theme: string | ThemeOption = '';
  options: EChartsOption = {};

  ngOnInit() {
    this.options = {
      title: {
        left: '50%',
        text: this.title,
        textAlign: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)'
      },
      // legend: {
      //   align: 'auto',
      //   bottom: 10
      // },
      calculable: true,
      series: [
        {
          name: 'Aircraft',
          type: 'pie',
          radius: [30, 110],
          roseType: 'area',
          data: this.data
        }
      ]
    };
  }

}

<!-- DataTales Example -->
<div class="card shadow mb-4">
    <div class="card-header py-3  d-flex justify-content-between align-items-center">
        <h6 class="m-0 font-weight-bold text-primary">Fleet</h6>
        <a [routerLink]="['/airadmin/buy']"  class="btn btn-success">Buy aircraft</a>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table *ngIf="fetched" datatable="ng" [dtOptions]="dtOptions" class="table table-bordered">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Capacity</th>
                        <th>Registration</th>
                        <th>Is cargo</th>
                        <th>Location</th>
                        <th>Fuel on board</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ac of aircrafts">
                        <td>{{ac.name}}</td>
                        <td>{{ac.type}}</td>
                        <td>{{ac.capacity}}</td>
                        <td>{{ac.registration}}</td>
                        <td><span class="badge"
                                [ngClass]="{'badge-warning' : ac.cargo==true, 'badge-info' : ac.cargo==false}">{{ac.cargo}}</span>
                        </td>
                        <td>{{ac.location}}</td>
                        <td>{{ac.fuelOnBoard}} kg</td>
                        <td>
                          <a *ngIf="isAdmin" [routerLink]="['/airadmin/fleet/', ac.id]" class="btn btn-info btn-circle btn-sm"><i class="fas fa-info-circle"></i></a>&nbsp;
                          <a *ngIf="!isAdmin" [routerLink]="['/user/fleet/', ac.id]" class="btn btn-info btn-circle btn-sm"><i class="fas fa-info-circle"></i></a>&nbsp;
                          <button *ngIf="isAdmin" (click)="setId(ac.id)" data-toggle="modal" data-target="#transferModal" type="button"
                                  class="btn btn-primary btn-circle btn-sm"><i class="fas fa-map-marked-alt"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="transferModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                <div class="modal-header">
                    <h5 class="modal-title font-weight-bold text-primary" id="exampleModalLabel">Transfer aircraft</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="text-primary" for="icao">ICAO</label>
                        <input type="text" class="form-control form-control-user" id="icao" name="icao"
                        [(ngModel)]="form.icao" #icao="ngModel"
                            aria-describedby="icaoHelp" required>
                    </div>

                </div>
                <div class="modal-footer">
                    <button id="closeModalButton" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Transfer</button>
                </div>
            </form>
        </div>
    </div>
</div>

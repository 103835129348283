<!-- DataTales Example -->
<div class="card shadow mb-4">
    <div class="card-header py-3  d-flex justify-content-between align-items-center">
        <h6 class="m-0 font-weight-bold text-primary">Special cargo</h6>
        <button data-toggle="modal" data-target="#generateModal" type="button" class="btn btn-success">Create</button>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table *ngIf="fetched" datatable="ng" [dtOptions]="dtOptions" class="table table-bordered table-hover"
                width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>Departure</th>
                        <th>Arrival</th>
                        <th>Weight (kg)</th>
                        <th>Description</th>
                        <th>Multiplication</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let spc of spcs">
                        <td>{{spc.departure}}</td>
                        <td>{{spc.arrival}}</td>
                        <td>{{spc.weight}}</td>
                        <td>{{spc.description}}</td>
                        <td>{{spc.multiplication}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="generateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                <div class="modal-header">
                    <h5 class="modal-title font-weight-bold text-primary" id="exampleModalLabel">Create special cargo
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="custom-control custom-switch">
                        <input [(ngModel)]="automatic" name="automatic" type="checkbox" class="custom-control-input" id="customSwitch1">
                        <label class="custom-control-label" for="customSwitch1">Auto generate</label>
                      </div>
                    <div *ngIf="!automatic">
                        <div class="form-group">
                            <label class="text-primary" for="maxCargo">Departure</label>
                            <input type="text" class="form-control form-control-user" id="departure" name="departure"
                                [(ngModel)]="form.departure" #departure="ngModel" aria-describedby="departureHelp"
                                required>
                        </div>
                        <div class="form-group">
                            <label class="text-primary" for="maxCargo">Arrival</label>
                            <input type="text" class="form-control form-control-user" id="arrival" name="arrival"
                                [(ngModel)]="form.arrival" #arrival="ngModel" aria-describedby="arrivalHelp" required>
                        </div>
                        <div class="form-group">
                            <label class="text-primary" for="weight">Weight</label>
                            <input type="text" class="form-control form-control-user" id="weight" name="weight"
                                [(ngModel)]="form.weight" #weight="ngModel" aria-describedby="weightHelp" required>
                        </div>
                        <div class="form-group">
                            <label class="text-primary" for="multiplication">Multiplication</label>
                            <input type="text" class="form-control form-control-user" id="multiplication"
                                name="multiplication" [(ngModel)]="form.multiplication" #multiplication="ngModel"
                                aria-describedby="multiplicationHelp" required>
                        </div>
                        <div class="form-group">
                            <label class="text-primary" for="description">Description</label>
                            <input type="text" class="form-control form-control-user" id="description"
                                name="description" [(ngModel)]="form.description" #description="ngModel"
                                aria-describedby="descriptionHelp" required>
                        </div>
                    </div>
                    <div *ngIf="automatic">
                        <div class="form-group">
                            <label class="text-primary" for="packetsNum">Number of packets</label>
                            <input type="text" class="form-control form-control-user" id="packetsNum" name="packetsNum"
                                [(ngModel)]="form.packetsNum" #packetsNum="ngModel" aria-describedby="packetsNumHelp"
                                required>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalButton" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Create</button>
                </div>
            </form>
        </div>
    </div>
</div>
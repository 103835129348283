<div class="container">

    <!-- Outer Row -->
    <br>
    <br>
    <br>
    <div class="row justify-content-center ">

        <div class="col-xl-10 col-lg-12 col-md-9">

            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                    <!-- Nested Row within Card Body -->
                    <div class="row">
                        <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                        <div class="col-lg-6">
                            <div class="p-5">
                                <div class="text-center">
                                    <h1 class="h4 text-gray-900 mb-4">Welcome to Intisia VA manager</h1>
                                    <h3 class="h5 text-gray-900 mb-4">Register</h3>
                                </div>
                                <form class="user" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                                    <div lass="form-group">
                                        <input type="text" class="form-control form-control-user" id="username"
                                            name="username" aria-describedby="emailHelp" placeholder="Enter username"
                                            [(ngModel)]="form.username" required #username="ngModel">
                                        <div class="alert alert-danger" role="alert"
                                            *ngIf="username.errors && f.submitted">
                                            Username is required!
                                        </div>
                                    </div>
                                    <br>
                                    <div class="form-group">
                                        <input type="email" class="form-control form-control-user" id="email"
                                            name="email" aria-describedby="emailHelp" placeholder="Enter email"
                                            [(ngModel)]="form.email" required #email="ngModel">
                                        <div class="alert alert-danger" role="alert"
                                            *ngIf="email.errors && f.submitted">
                                            Email is required!
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control form-control-user" id="fullName"
                                            name="fullName" aria-describedby="fullNameHelp" placeholder="Enter full name"
                                            [(ngModel)]="form.fullName" required #fullName="ngModel">
                                        <div class="alert alert-danger" role="alert"
                                            *ngIf="fullName.errors && f.submitted">
                                            Full name is required!
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input type="password" class="form-control form-control-user" id="password"
                                            name="password" placeholder="Password" [(ngModel)]="form.password" required
                                            #password="ngModel">
                                        <div class="alert alert-danger" role="alert"
                                            *ngIf="password.errors && f.submitted">
                                            <div *ngIf="password.errors.required">Password is required</div>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch">
                                        <input [(ngModel)]="createAirline" name="createAirline" type="checkbox" class="custom-control-input" id="customSwitch1">
                                        <label class="custom-control-label" for="customSwitch1">Create airline</label>
                                      </div>
                                    <div *ngIf="createAirline">
                                        <div class="form-group">
                                            <input type="text" class="form-control form-control-user" id="airlineName"
                                                name="airlineName" aria-describedby="airlineNameHelp" placeholder="Enter airline name"
                                                [(ngModel)]="form.airlineName" required #airlineName="ngModel">
                                            <div class="alert alert-danger" role="alert"
                                                *ngIf="airlineName.errors && f.submitted">
                                                Airline name is required!
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control form-control-user" id="airlineIcao"
                                                name="airlineIcao" aria-describedby="airlineIcaoHelp" placeholder="Enter airline icao"
                                                [(ngModel)]="form.airlineIcao" required #airlineIcao="ngModel">
                                            <div class="alert alert-danger" role="alert"
                                                *ngIf="airlineIcao.errors && f.submitted">
                                                Airline icao is required!
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control form-control-user" id="airlineBaseIcao"
                                                name="airlineBaseIcao" aria-describedby="airlineBaseIcaoHelp" placeholder="Enter airline base icao"
                                                [(ngModel)]="form.airlineBaseIcao" required #airlineBaseIcao="ngModel">
                                            <div class="alert alert-danger" role="alert"
                                                *ngIf="airlineBaseIcao.errors && f.submitted">
                                                Airline base icao is required!
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="number" class="form-control form-control-user" id="startingBalance"
                                                name="startingBalance" aria-describedby="startingBalanceHelp"
                                                [(ngModel)]="form.startingBalance" required #startingBalance="ngModel">
                                            <div class="alert alert-danger" role="alert"
                                                *ngIf="startingBalance.errors && f.submitted">
                                                Airline starting balance is required!
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!createAirline">
                                        <div class="form-group">
                                            <label class="text-primary" for="airlineId">Join airline</label>
                                            <select name="airlineId" class="form-control" [(ngModel)]="form.airlineId" #airlineId="ngModel" required>
                                                <option *ngFor="let airline of airlines" [value]="airline.id">
                                                    {{airline.name}} ({{airline.icao}}, base: {{airline.base}})
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <button class="btn btn-primary btn-user btn-block">
                                        Register
                                    </button>
                                    <hr>
                                    <p>Already have account? <a [routerLink]="['/login']">Login here</a></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>
<div class="row">
    <div class="card shadow mb-4 col-lg-12">
        <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Pilot profile</h6>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-xl-4 col-md-4 mb-4">
                    <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Full name</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">
                                        {{pilot.fullName}}
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-user fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-4 mb-4">
                    <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Username</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">
                                        {{pilot.userName}}
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i style="cursor: pointer;" data-toggle="modal" data-target="#passwordModal" class="fas fa-user-shield fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-4 mb-4">
                    <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Email</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">
                                        {{pilot.email}}
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-envelope fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-4 col-md-4 mb-4">
                    <div class="card border-left-success shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Current location</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">
                                        {{pilot.location}}
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i data-toggle="modal" data-target="#locationModal" class="fas fa-map-marked-alt fa-2x text-gray-300" style="cursor: pointer;"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-4 mb-4">
                    <div class="card border-left-success shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Hours</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">
                                        {{pilot.minutes | m2h}}
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-history fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-4 mb-4">
                    <div class="card border-left-success shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Secret code</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">
                                        {{pilot.secretCode}}
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-key fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          <div class="row">
            <div class="col-md-6 offset-3">
              <h4>Favorite airport pair: <b>{{statisticsPer.favoritePair}}</b></h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <app-pie *ngIf="loaded" [data]="statisticsPer.perDeparture" title="Flights per departure (top 20)"></app-pie>
            </div>
            <div class="col-md-4">
              <app-pie *ngIf="loaded" [data]="statisticsPer.perArrival" title="Flights per arrival (top 20)"></app-pie>
            </div>
            <div class="col-md-4">
              <app-pie *ngIf="loaded" [data]="statisticsPer.perAircraftType" title="Flights per aircraft"></app-pie>
            </div>
          </div>
        </div>
    </div>
</div>

<div class="row">
    <!-- Content Column -->
    <div class="col-lg-12 mb-12">
        <!-- Project Card Example -->
        <div class="card shadow mb-4">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Last 5 flights</h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Departure</th>
                                <th>Arrival</th>
                                <th>Start time</th>
                                <th>End time</th>
                                <th>Aircraft</th>
                                <th>Profit</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let flight of pilot.flights">
                                <td>{{flight.departure}}</td>
                                <td>{{flight.arrival}}</td>
                                <td>{{flight.startTime}}</td>
                                <td>{{flight.endTime}}</td>
                                <td>{{flight.aircraft}} ({{flight.landingRate | number:'1.0-0'}} ft/min)</td>
                                <td>${{flight.profit}}</td>
                                <td>
                                    <a *ngIf="isAdmin" [routerLink]="['/airadmin/pirep', flight.id]"
                                    class="btn btn-info btn-circle btn-sm"><i class="fas fa-info-circle"></i></a>
                                <a *ngIf="!isAdmin" [routerLink]="['/user/pirep', flight.id]"
                                    class="btn btn-info btn-circle btn-sm"><i class="fas fa-info-circle"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="locationModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <form>
                <div class="modal-body">
                    <div class="custom-control custom-switch">
                    </div>
                    <div class="form-group">
                        <label class="text-primary" for="location">Location</label>
                        <input id="location" name="location" class="form-control" [(ngModel)]="form.location" #location="ngModel" required>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalButton" type="button" class="btn btn-secondary"
                        data-dismiss="modal">Close</button>
                    <button (click)="changeLocation()" type="button" class="btn btn-primary">Switch location</button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal fade" id="passwordModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form>
        <div class="modal-body">
          <div class="custom-control custom-switch">
          </div>
          <div class="form-group">
            <label class="text-primary" for="newPassword">Change password</label>
            <input placeholder="New Password" id="newPassword" name="newPassword" class="form-control" [(ngModel)]="form.newPassword" #location="ngModel" required>
          </div>
        </div>
        <div class="modal-footer">
          <button id="closeModalButton2" type="button" class="btn btn-secondary"
                  data-dismiss="modal">Close</button>
          <button (click)="changePassword()" type="button" class="btn btn-primary">Change password</button>
        </div>
      </form>
    </div>
  </div>
</div>

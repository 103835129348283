import { Component, OnInit } from '@angular/core';
import {AirlineAircraft} from "../../../models/airline-aircraft.model";
import {AircraftService} from "../../../services/aircraft.service";
import {TokenService} from "../../../services/token.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-aircraft-details',
  templateUrl: './aircraft-details.component.html',
  styleUrls: ['./aircraft-details.component.css']
})
export class AircraftDetailsComponent implements OnInit {
  aircraft: AirlineAircraft;
  id: number;
  isAdmin = false;
  dtOptions: DataTables.Settings = {};
  fetched: boolean = false;

  constructor(private aircraftService: AircraftService, private actRoute: ActivatedRoute, private tokenService: TokenService) {}

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      lengthMenu: [5, 10, 25],
      processing: true,
      order: [],
    };

    this.actRoute.paramMap.subscribe(params => {
      this.id = Number(params.get('id'));
      this.aircraftService.readAllAirlineAircraftDetails(this.id).subscribe(data => {
        this.aircraft = data;
        this.fetched = true;
      });
    });

    if (this.tokenService.getUser().roles.includes('ROLE_ADMIN')) {
      this.isAdmin = true;
    }
  }

}

import { UserService } from './../../../services/user.service';
import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
})
export class UserListComponent implements OnInit {
  @Input()
  pilots: boolean = false;
  users: User[];
  dtOptions: DataTables.Settings = {};
  fetched: boolean = false;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      lengthMenu: [5, 10, 25],
      processing: true,
      order: [],
    };

    this.loadData();
  }

  loadData() {
    if (!this.pilots) {
      this.userService.readAllUsers().subscribe((data) => {
        this.users = data;
        this.fetched = true;
      });
    } else {
      this.userService.readAirlinePilots().subscribe((data) => {
        this.users = data;
        this.fetched = true;
      });
    }
  }

  resetPassword(userName: string) {
    this.userService.resetPassword(userName).subscribe(data => {
      alert("Password reset")
    })
  }

  deleteUser(username: string) {
    this.userService.deleteUser(username).subscribe(data => {
      alert("User delete")
      this.loadData();
    })
  }
}

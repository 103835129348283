import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MetarService {

  constructor(private http :HttpClient) { }

  getMetar(icao) {
    const headerDict = {
      'Authorization': null,
    }
    
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    return this.http.get<any>('https://avwx.rest/api/metar/' + icao + '?token=AduMGyDf-aOdLLthklnQ-WGWCwE8RLfaxGRZBBOX3hI');
  }
}

import { Airline } from './../models/airline.model';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Booking } from '../models/booking.model';
import { Dashboard } from '../models/dashboard.model';
import { Flight } from '../models/flight.model';

@Injectable({
  providedIn: 'root',
})
export class AirlineService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_API_URL') private baseUrl: string
  ) {}

  readDashboard() {
    return this.http
      .get(this.baseUrl + '/airline/admin/dashboard')
      .pipe(map((data: any) => this.convertObjectToDashboard(data)));
  }

  readAirlines() {
    return this.http
      .get(this.baseUrl + '/auth/register/airlines')
      .pipe(
        map((data: any[]) =>
          data.map((item: any) => this.convertObjectToAirline(item))
        )
      );
  }

  private convertObjectToDashboard(item: any) {
    return new Dashboard(
      item.name,
      item.icao,
      item.numberOfFlights,
      item.numberOfPilots,
      item.balance,
      item.numberOfPlanes,
      item.lastFiveFlights.map((data) => this.convertObjectToFlight(data)),
      item.bookings.map((data) => this.convertObjectToBooking(data))
    );
  }

  private convertObjectToFlight(item: any) {
    return new Flight(
      item.id,
      item.departure,
      item.arrival,
      item.startTime,
      item.endTime,
      item.length,
      item.aircraft,
      item.user,
      item.fuelSpent,
      item.earning,
      item.fuelCost,
      item.profit,
      item.info,
      item.landingRate,
      item.fuelStart,
      item.fuelEnd,
      item.fuelBought,
      item.deplat,
      item.deplon,
      item.arlat,
      item.arlon
    );
  }

  private convertObjectToBooking(item) {
    return new Booking(
      item.departure,
      item.arrival,
      item.flightNumber,
      item.passengers,
      item.cargo,
      item.user,
      item.aircraft,
      item.specialCargo,
      item.deplat,
      item.deplon,
      item.arlat,
      item.arlon
    );
  }

  private convertObjectToAirline(item) {
    return new Airline(item.id, item.name, item.icao, item.base);
  }
}

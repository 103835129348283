<!-- DataTales Example -->
<div class="card shadow mb-4">
    <div class="card-header py-3  d-flex justify-content-between align-items-center">
        <h6 class="m-0 font-weight-bold text-primary">Airplanes</h6>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table *ngIf="fetched" datatable="ng" [dtOptions]="dtOptions" class="table table-bordered">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>ICAO</th>
                        <th>Price</th>
                        <th>Max passangers</th>
                        <th>Is cargo</th>
                        <th *ngIf="systemAdmin">Max cargo</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ac of aircrafts">
                        <td>{{ac.name}}</td>
                        <td>{{ac.icao}}</td>
                        <td>{{ac.price}}</td>
                        <td>{{ac.maxPassengers}}</td>
                        <td><span class="badge"
                                [ngClass]="{'badge-warning' : ac.cargo==true, 'badge-info' : ac.cargo==false}">{{ac.cargo}}</span>
                        </td>
                        <td *ngIf="systemAdmin">{{ac.maxCargo}}</td>
                        <td>
                            <button *ngIf="systemAdmin" (click)="convertClick(ac.id)" data-toggle="modal"
                                data-target="#convertModal" type="button" class="btn btn-warning btn-circle btn-sm"><i
                                    class="fas fa-archive"></i></button>

                            <button *ngIf="!systemAdmin" (click)="buy(ac.id, ac.icao, ac.cargo, ac.maxPassengers)" data-toggle="modal"
                            data-target="#buyModal" type="button" class="btn btn-success btn-circle btn-sm"><i
                                    class="fas fa-shopping-cart"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="convertModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                <div class="modal-header">
                    <h5 class="modal-title font-weight-bold text-primary" id="exampleModalLabel">Convert to cargo</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="text-primary" for="maxCargo">Max cargo</label>
                        <input type="number" class="form-control form-control-user" id="maxCargo" name="maxCargo"
                            [(ngModel)]="form.maxCargo" #maxCargo="ngModel" aria-describedby="usernameHelp" required>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Save changes</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="buyModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <form (ngSubmit)="onSubmitBuy()" #f="ngForm" novalidate>
                <div class="modal-header">
                    <h5 class="modal-title font-weight-bold text-primary" id="exampleModalLabel">Buy aircraft</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="text-primary" for="icao">ICAO</label>
                        <input type="text" class="form-control form-control-user" id="icao" name="icao"
                            [(ngModel)]="form1.icao" #icao="ngModel" aria-describedby="icaoHelp" required>
                    </div>
                    <div class="form-group">
                        <label class="text-primary" for="icao">Pax number</label>
                        <input type="text" class="form-control form-control-user" id="paxNum" name="paxNum"
                            [(ngModel)]="form1.paxNum" #paxNum="ngModel" aria-describedby="paxNumHelp" required>
                    </div>
                    <div class="form-group">
                        <label class="text-primary" for="icao">Registration</label>
                        <input type="text" class="form-control form-control-user" id="registration" name="registration"
                            [(ngModel)]="form1.registration" #icao="ngModel" aria-describedby="registrationHelp" required>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeBuyModal" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Buy</button>
                </div>
            </form>
        </div>
    </div>
</div>
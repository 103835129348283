import { SpecialCargo } from './../models/special-cargo.model';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpecialCargoService {

  constructor(
    private http: HttpClient,
    @Inject('BASE_API_URL') private baseUrl: string
  ) {}

  readAllSpc() {
    return this.http
      .get(this.baseUrl + '/admin/spc/all')
      .pipe(
        map((data: any[]) =>
          data.map((item: any) => this.convertObjectToSpc(item))
        )
      );
  }

  createAutomatic(num: number) {
    return this.http.post(this.baseUrl + "/admin/spc/automatic/" + num, null);
  }

  createManual(spc: SpecialCargo) {
    return this.http.post(this.baseUrl + "/admin/spc", spc);
  }

  private convertObjectToSpc(item: any) {
    return new SpecialCargo(
      item.id,
      item.weight,
      item.multiplication,
      item.description,
      item.departure,
      item.arrival
    );
  }
}
import { Router } from '@angular/router';
import { SpecialCargo } from './../../../models/special-cargo.model';
import { Component, OnInit } from '@angular/core';
import { SpecialCargoService } from 'src/app/services/special-cargo.service';

@Component({
  selector: 'app-special-cargo-list',
  templateUrl: './special-cargo-list.component.html',
  styleUrls: ['./special-cargo-list.component.css'],
})
export class SpecialCargoListComponent implements OnInit {
  spcs: SpecialCargo[];
  dtOptions: DataTables.Settings = {};
  fetched: boolean = false;
  automatic: boolean = false;
  form: any = {
    departure: null,
    arrival: null,
    weight: 0,
    multiplication: 0,
    description: null,
    packetsNum: 0,
  };

  constructor(
    private specialCargoService: SpecialCargoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      lengthMenu: [5, 10, 25],
      processing: true,
      order: [],
    };

    this.loadData();
  }

  loadData() {
    this.specialCargoService.readAllSpc().subscribe((data) => {
      this.spcs = data;
      this.fetched = true;
    });
  }

  onSubmit() {
    if (this.automatic) {
      this.specialCargoService
        .createAutomatic(this.form.packetsNum)
        .subscribe((data) => {
          this.fetched = false;
          this.loadData();
          document.getElementById('closeModalButton').click();
        });
    } else {
      this.specialCargoService
        .createManual(
          new SpecialCargo(
            null,
            this.form.weight,
            this.form.multiplication,
            this.form.description,
            this.form.departure,
            this.form.arrival
          )
        )
        .subscribe((data) => {
          this.fetched = false;
          this.loadData();
          document.getElementById('closeModalButton').click();
        });
    }
  }
}

import { MinutesToHours } from './minutes-hours.pipe';
import { RouteFilter } from './route-filter.pipe';
import { AirlineService } from './services/airline.service';
import { FlightService } from './services/flight.service';
import { AircraftService } from './services/aircraft.service';
import { MetarService } from './services/metar.service';
import { UserService } from './services/user.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { MainComponent } from './components/main/main.component';
import { UserListComponent } from './components/users/user-list/user-list.component';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { TokenService } from './services/token.service';
import { FormsModule } from '@angular/forms';
import { AuthGuard } from './helpers/auth.guard';
import { AuthInterceptor } from './helpers/auth.interceptor';
import { DataTablesModule } from 'angular-datatables';
import { AircraftListComponent } from './components/aircraft/aircraft-list/aircraft-list.component';
import { FlightListComponent } from './components/flight/flight-list/flight-list.component';
import { RemoveCommaPipe } from './remove-comma.pipe';
import { SpecialCargoListComponent } from './components/spc/special-cargo-list/special-cargo-list.component';
import { SpecialCargoService } from './services/special-cargo.service';
import { AirlineDashboardComponent } from './components/airline/airline-dashboard/airline-dashboard.component';
import { RouteListComponent } from './components/routes/route-list/route-list.component';
import { RouteService } from './services/route.service';
import { BookFlightComponent } from './components/routes/book-flight/book-flight.component';
import { BookingComponent } from './components/routes/booking/booking.component';
import { DispatchComponent } from './components/routes/dispatch/dispatch.component';
import { PirepComponent } from './components/routes/pirep/pirep.component';
import { ProfileComponent } from './components/users/profile/profile.component';
import { AirlineAircraftListComponent } from './components/aircraft/airline-aircraft-list/airline-aircraft-list.component';
import { UserListViewComponent } from './components/users/user-list-view/user-list-view.component';
import { PilotListViewComponent } from './components/users/pilot-list-view/pilot-list-view.component';
import { RegisterComponent } from './components/users/register/register.component';
import { AircraftDetailsComponent } from './components/aircraft/aircraft-details/aircraft-details.component';
import {NgxEchartsModule} from "ngx-echarts";
import { PieComponent } from './components/pie/pie.component';

const routes: Routes = [
  // osnovne rute
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  {
    path: 'admin',
    component: MainComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_SYSTEM_ADMIN'] },
    children: [
      {
        path: 'users', // child route path
        component: UserListViewComponent, // child route component that the router renders
      },
      {
        path: 'aircrafts', // child route path
        component: AircraftListComponent, // child route component that the router renders
      },
      {
        path: 'flights', // child route path
        component: FlightListComponent, // child route component that the router renders
      },
      {
        path: 'spc', // child route path
        component: SpecialCargoListComponent, // child route component that the router renders
      },
    ],
  },
  {
    path: 'airadmin',
    component: MainComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_ADMIN'] },
    children: [
      {
        path: 'dashboard', // child route path
        component: AirlineDashboardComponent, // child route component that the router renders
      },
      {
        path: 'routes', // child route path
        component: RouteListComponent, // child route component that the router renders
      },
      {
        path: 'booking', // child route path
        component: BookFlightComponent, // child route component that the router renders
      },
      {
        path: 'dispatch', // child route path
        component: DispatchComponent, // child route component that the router renders
      },
      {
        path: 'pireps', // child route path
        component: FlightListComponent, // child route component that the router renders
      },
      {
        path: 'pirep/:id', // child route path
        component: PirepComponent, // child route component that the router renders
      },
      {
        path: 'profile', // child route path
        component: ProfileComponent, // child route component that the router renders
      },
      {
        path: 'profile-info/:username', // child route path
        component: ProfileComponent, // child route component that the router renders
      },
      {
        path: 'fleet', // child route path
        component: AirlineAircraftListComponent, // child route component that the router renders
      },
      {
        path: 'fleet/:id', // child route path
        component: AircraftDetailsComponent, // child route component that the router renders
      },
      {
        path: 'buy', // child route path
        component: AircraftListComponent, // child route component that the router renders
      },
      {
        path: 'pilots', // child route path
        component: PilotListViewComponent, // child route component that the router renders
      },
    ],
  },
  {
    path: 'user',
    component: MainComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_USER'] },
    children: [
      {
        path: 'booking', // child route path
        component: BookFlightComponent, // child route component that the router renders
      },
      {
        path: 'dispatch', // child route path
        component: DispatchComponent, // child route component that the router renders
      },
      {
        path: 'pireps', // child route path
        component: FlightListComponent, // child route component that the router renders
      },
      {
        path: 'pirep/:id', // child route path
        component: PirepComponent, // child route component that the router renders
      },
      {
        path: 'profile', // child route path
        component: ProfileComponent, // child route component that the router renders
      },
      {
        path: 'fleet', // child route path
        component: AirlineAircraftListComponent, // child route component that the router renders
      },
      {
        path: 'fleet/:id', // child route path
        component: AircraftDetailsComponent, // child route component that the router renders
      },
    ],
  },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    UserListComponent,
    AircraftListComponent,
    FlightListComponent,
    RemoveCommaPipe,
    SpecialCargoListComponent,
    AirlineDashboardComponent,
    RouteListComponent,
    BookFlightComponent,
    RouteFilter,
    BookingComponent,
    DispatchComponent,
    PirepComponent,
    ProfileComponent,
    MinutesToHours,
    AirlineAircraftListComponent,
    UserListViewComponent,
    PilotListViewComponent,
    RegisterComponent,
    AircraftDetailsComponent,
    PieComponent
  ],
  imports: [
    BrowserModule,
    DataTablesModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    RouterModule.forRoot(routes),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: 'BASE_API_URL', useValue: environment.apiUrl },
    AuthService,
    TokenService,
    UserService,
    MetarService,
    AircraftService,
    FlightService,
    SpecialCargoService,
    AirlineService,
    RouteService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

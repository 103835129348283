import { RouteService } from 'src/app/services/route.service';
import { AirlineService } from './../../../services/airline.service';
import { Component, OnInit } from '@angular/core';
import { Dashboard } from 'src/app/models/dashboard.model';
import {StatisticsService} from "../../../services/statistics.service";
import {StatisticsPer} from "../../../models/statistics-per.model";

@Component({
  selector: 'app-airline-dashboard',
  templateUrl: './airline-dashboard.component.html',
  styleUrls: ['./airline-dashboard.component.css']
})
export class AirlineDashboardComponent implements OnInit {

  airline: Dashboard
  statisticsPer: StatisticsPer

  loaded = false;

  constructor(private airlineService: AirlineService, private routeService: RouteService,
              private statisticsService: StatisticsService) { }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.airlineService.readDashboard().subscribe((data) => {
      this.airline = data
    });
    this.statisticsService.getStatisticsPerSomething().subscribe(data => {
      this.statisticsPer = data
      this.loaded = true;
    })
  }

  cancelBooking(username: string) {
    this.routeService.cancelBookingForUser(username).subscribe((date) => {
      this.load();
    });

  }

}

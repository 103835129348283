<div class="container">

    <!-- Outer Row -->
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <div class="row justify-content-center ">

        <div class="col-xl-10 col-lg-12 col-md-9">

            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                    <!-- Nested Row within Card Body -->
                    <div class="row">
                        <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                        <div class="col-lg-6">
                            <div class="p-5">
                                <div class="text-center">
                                    <h1 class="h4 text-gray-900 mb-4">Welcome to Intisia VA manager</h1>
                                </div>
                                <form class="user" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                                    <div class="form-group">
                                        <input type="text" class="form-control form-control-user" id="username"
                                            name="username" aria-describedby="emailHelp" placeholder="Enter username"
                                            [(ngModel)]="form.username" required #username="ngModel">
                                        <div class="alert alert-danger" role="alert"
                                            *ngIf="username.errors && f.submitted">
                                            Username is required!
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input type="password" class="form-control form-control-user" id="password"
                                            name="password" placeholder="Password" [(ngModel)]="form.password" required
                                            #password="ngModel">
                                        <div class="alert alert-danger" role="alert"
                                            *ngIf="password.errors && f.submitted">
                                            <div *ngIf="password.errors.required">Password is required</div>
                                        </div>
                                    </div>
                                    <button class="btn btn-primary btn-user btn-block">
                                        Login
                                    </button>
                                    <div class="form-group">
                                        <div class="alert alert-danger" role="alert"
                                            *ngIf="f.submitted && isLoginFailed">
                                            Login failed: {{ errorMessage }}
                                        </div>
                                    </div>
                                    <hr>
                                    <p>Don't have account yet? <a [routerLink]="['/register']">Register here</a></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>
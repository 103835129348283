import { MetarService } from './../../../services/metar.service';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';
import { RouteService } from 'src/app/services/route.service';
import { Booking } from './../../../models/booking.model';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as L from 'leaflet';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css'],
})
export class BookingComponent implements AfterViewInit {
  booking: Booking;
  depMetar: string;
  arrMetar: string;
  loaded: boolean = false;
  depUrl: any;
  arrUrl: any;

  private map;

  private initMap(): void {
    const departureIcon = L.divIcon({
      html:
        '<span class="text-success"><i class="fas fa-plane-departure fa-3x"></i></span>',
      iconSize: [20, 20],
      className: 'myDivIcon',
    });
    const arrivallIcon = L.divIcon({
      html:
        '<span class="text-danger"><i class="fas fa-plane-arrival fa-3x"></i></span>',
      iconSize: [20, 20],
      className: 'myDivIcon',
    });
    const deplon = this.booking.deplon;
    const deplat = this.booking.deplat;
    const arlon = this.booking.arlon;
    const arlat = this.booking.arlat;
    this.map = L.map('map', {
      center: [deplat, deplon],
      zoom: 3,
    }).fitBounds(
      [
        [deplat, deplon],
        [arlat, arlon],
      ],
      { padding: [50, 50] }
    );

    const tiles = L.tileLayer(
      'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png',
      {
        maxZoom: 18,
        minZoom: 3,
        attribution:
          '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
      }
    );

    tiles.addTo(this.map);

    const depMarker = L.marker([deplat, deplon], {
      title: this.booking.departure,
      icon: departureIcon,
    });

    depMarker.addTo(this.map);
    depMarker
      .bindPopup(this.booking.departure, {
        autoClose: false,
        closeOnClick: false,
      })
      .openPopup();

    const arrMarker = L.marker([arlat, arlon], {
      title: this.booking.arrival,
      icon: arrivallIcon,
    });

    arrMarker.addTo(this.map);
    arrMarker
      .bindPopup(this.booking.arrival, {
        autoClose: false,
        closeOnClick: false,
      })
      .openPopup();

    var polylinePoints = [
      [deplat, deplon],
      [arlat, arlon],
    ];

    var polyline = L.polyline(polylinePoints).addTo(this.map);
  }

  constructor(
    private routeService: RouteService,
    private tokenService: TokenService,
    private router: Router,
    private metarService: MetarService,
    private sanitizer: DomSanitizer
  ) {}

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnInit(): void {
    this.routeService.getUserBooking().subscribe((data) => {
      this.booking = data;
      this.depUrl =
        'https://metar-taf.com/embed/' +
        this.booking.departure +
        '?bg_color=0057a3';
      this.arrUrl =
        'https://metar-taf.com/embed/' +
        this.booking.arrival +
        '?bg_color=0057a3';
      this.depUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.depUrl);
      this.arrUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.arrUrl);
      console.log(this.depUrl);
      this.metarService.getMetar(this.booking.departure).subscribe((data) => {
        this.depMetar = data;
      });
      this.metarService.getMetar(this.booking.arrival).subscribe((data) => {
        this.arrMetar = data;
      });
      this.loaded = true;
    });
  }

  cancelBooking() {
    this.routeService.cancelBooking().subscribe((data) => {
      if (this.tokenService.getUser().roles.includes('ROLE_ADMIN')) {
        this.router.navigate(['/airadmin/booking']);
      } else {
        this.router.navigate(['/user/booking']);
      }
    });
  }
}

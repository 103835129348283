import { TokenService } from './../../../services/token.service';
import { UserService } from './../../../services/user.service';
import { User } from 'src/app/models/user.model';
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {StatisticsService} from "../../../services/statistics.service";
import {StatisticsPer} from "../../../models/statistics-per.model";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  pilot: User;
  form: any = {
    location: '',
    newPassword: ''
  };
  isAdmin = false;
  username = null;
  loaded = false;
  statisticsPer: StatisticsPer

  constructor(
    private userService: UserService,
    private tokenService: TokenService,
    private activatedRoute: ActivatedRoute,
    private statisticsService: StatisticsService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.username = params.get('username');
      this.loadData(this.username);
      if (this.tokenService.getUser().roles.includes('ROLE_ADMIN')) {
        this.isAdmin = true;
      }
      this.statisticsService.getStatsForUser(this.username).subscribe(data => {
        this.statisticsPer = data;
        this.statisticsPer.perDeparture.sort((a,b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0));
        this.statisticsPer.perArrival.sort((a,b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0));
        this.statisticsPer.perDeparture.length = 20;
        this.statisticsPer.perArrival.length = 20;
        this.loaded = true;
      })
    });

  }

  loadData(username) {
    this.userService.readProfile(username).subscribe((data) => {
      this.pilot = data;
    });
  }

  changeLocation() {
    this.userService.changeLocation(this.form.location).subscribe((data) => {
      document.getElementById('closeModalButton').click();
      this.loadData(this.username);
    });
  }

  changePassword() {
    this.userService.changePassword(this.form.newPassword).subscribe((data) => {
      document.getElementById('closeModalButton2').click();
      this.loadData(this.username);
    });
  }
}

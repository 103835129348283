import { TokenService } from './../../../services/token.service';
import { AirlineAircraft } from './../../../models/airline-aircraft.model';
import { AircraftService } from './../../../services/aircraft.service';
import { AirlineService } from './../../../services/airline.service';
import { Component, OnInit } from '@angular/core';
import { Aircraft } from 'src/app/models/aircraft.model';

@Component({
  selector: 'app-airline-aircraft-list',
  templateUrl: './airline-aircraft-list.component.html',
  styleUrls: ['./airline-aircraft-list.component.css'],
})
export class AirlineAircraftListComponent implements OnInit {
  aircrafts: AirlineAircraft[];
  dtOptions: DataTables.Settings = {};
  fetched: boolean = false;
  form: any = {
    id: null,
    icao: null,
  };
  isAdmin = false;

  constructor(private aircraftService: AircraftService, private tokenService: TokenService) {}

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      lengthMenu: [5, 10, 25],
      processing: true,
      order: [],
    };

    this.loadData();

    if (this.tokenService.getUser().roles.includes('ROLE_ADMIN')) {
      this.isAdmin = true;
    }
  }

  loadData() {
    this.aircraftService.readAllAirlineAircraft().subscribe((data) => {
      this.aircrafts = data;
      this.fetched = true;
    });
  }

  setId(id: number) {
    this.form.id = id;
  }

  onSubmit() {
    this.aircraftService
      .changeLocation(this.form.id, this.form.icao)
      .subscribe((data) => {
        document.getElementById('closeModalButton').click();
        this.ngOnInit();
      });
  }
}

<!-- DataTales Example -->
<div class="card shadow mb-4">
    <div class="card-header py-3  d-flex justify-content-between align-items-center">
        <h6 class="m-0 font-weight-bold text-primary">Routes</h6>
        <button data-toggle="modal" data-target="#routeModal" type="button" class="btn btn-success">Create
            route</button>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table *ngIf="fetched" datatable="ng" [dtOptions]="dtOptions" class="table table-bordered table-hover"
                width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>Flight number</th>
                        <th>Departure</th>
                        <th>Arrival</th>
                        <th>Ticket price</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let route of routes">
                        <td>{{route.flightNumber}}</td>
                        <td>{{route.departure}}</td>
                        <td>{{route.arrival}}</td>
                        <td>${{route.ticketPrice}}</td>
                        <td>
                            <button type="button" class="btn btn-danger btn-circle btn-sm"><i
                                    class="fas fa-trash"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="modal fade" id="routeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                <div class="modal-body">
                    <div class="custom-control custom-switch">
                    </div>
                    <div class="form-group">
                        <label class="text-primary" for="maxCargo">Departure</label>
                        <input type="text" class="form-control form-control-user" id="departure" name="departure"
                            [(ngModel)]="form.departure" #departure="ngModel" aria-describedby="departureHelp" required>
                    </div>
                    <div class="form-group">
                        <label class="text-primary" for="maxCargo">Arrival</label>
                        <input type="text" class="form-control form-control-user" id="arrival" name="arrival"
                            [(ngModel)]="form.arrival" #arrival="ngModel" aria-describedby="arrivalHelp" required>
                    </div>
                    <div class="custom-control custom-switch">
                        <input [(ngModel)]="form.returnFlight" name="automatic" type="checkbox" class="custom-control-input" id="customSwitch1">
                        <label class="custom-control-label" for="customSwitch1">Return flight?</label>
                      </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalButton" type="button" class="btn btn-secondary"
                        data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Create</button>
                </div>
            </form>
        </div>
    </div>
</div>
<div id="wrapper">

    <!-- Sidebar -->
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

        <!-- Sidebar - Brand -->
        <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
            <div class="sidebar-brand-icon rotate-n-15">
                <i class="fas fa-plane"></i>
            </div>
            <div class="sidebar-brand-text mx-3">Intisia va</div>
        </a>

        <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <div *ngIf="role=='ROLE_ADMIN'">
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/airadmin/dashboard']">
                    <i class="fas fa-fw fa-tachometer-alt"></i>
                    <span>Dashboard</span></a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/airadmin/profile']">
                    <i class="fas fa-fw fa-user"></i>
                    <span>Profile</span></a>
            </li>
        </div>

        <div *ngIf="role=='ROLE_USER'">
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/user/profile']">
                    <i class="fas fa-fw fa-user"></i>
                    <span>Profile</span></a>
            </li>
        </div>

        <div *ngIf="role=='ROLE_ADMIN'">
            <!-- Divider -->
            <hr class="sidebar-divider">
            <div class="sidebar-heading">
                Flight centre
            </div>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/airadmin/booking']">
                    <i class="fas fa-plane-departure"></i>
                    <span>Book flight</span></a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/airadmin/dispatch']">
                    <i class="fas fa-print"></i>
                    <span>Dispatch centre</span></a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/airadmin/pireps']">
                    <i class="fas fa-plane-arrival"></i>
                    <span>Pireps</span></a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/airadmin/routes']">
                    <i class="fas fa-map-marked-alt"></i>
                    <span>Routes</span></a>
            </li>
        </div>

        <div *ngIf="role=='ROLE_USER'">
            <!-- Divider -->
            <hr class="sidebar-divider">
            <div class="sidebar-heading">
                Flight centre
            </div>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/user/booking']">
                    <i class="fas fa-plane-departure"></i>
                    <span>Book flight</span></a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/user/dispatch']">
                    <i class="fas fa-print"></i>
                    <span>Dispatch centre</span></a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/user/pireps']">
                    <i class="fas fa-plane-arrival"></i>
                    <span>Pireps</span></a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/user/fleet']">
                    <i class="fas fa-plane"></i>
                    <span>Fleet</span></a>
            </li>
        </div>

        <div *ngIf="role=='ROLE_ADMIN'">
            <!-- Divider -->
            <hr class="sidebar-divider">
            <div class="sidebar-heading">
                Administration
            </div>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/airadmin/pilots']">
                    <i class="fas fa-users"></i>
                    <span>Pilots</span></a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/airadmin/fleet']">
                    <i class="fas fa-plane"></i>
                    <span>Airplanes</span></a>
            </li>
        </div>


        <!-- Heading -->
        <!-- <div class="sidebar-heading">
            Library
        </div> -->

        <!-- Nav Item - Pages Collapse Menu -->
        <!-- <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" href="tables.html">
                <i class="fas fa-book"></i>
                <span> Books</span></a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" href="tables.html">
                <i class="fas fa-tags"></i>
                <span> Tags</span></a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" href="tables.html">
                <i class="fas fa-user"></i>
                <span> Authors</span></a>
        </li> -->

        <!-- Divider -->
        <!-- <hr class="sidebar-divider"> -->

        <!-- Heading -->
        <div *ngIf="role=='ROLE_SYSTEM_ADMIN'">
            <div class="sidebar-heading">
                Administration
            </div>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/admin/users']">
                    <i class="fas fa-users"></i>
                    <span> Users</span></a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/admin/aircrafts']">
                    <i class="fas fa-plane"></i>
                    <span> Aircrafts</span></a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/admin/flights']">
                    <i class="fas fa-fighter-jet"></i>
                    <span> All flights</span></a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/admin/spc']">
                    <i class="fas fa-archive"></i>
                    <span> Special cargo</span></a>
            </li>
        </div>
        <div class="sidebar-heading">
            Downloads
        </div>
        <li class="nav-item">
            <a target="_blank" class="nav-link" href="https://drive.google.com/file/d/1oOV7jWhtDElQSvPTHlOAduSWKrouheR3/view?usp=sharing">
                <i class="fas fa-cloud-download-alt"></i>
                <span> Download client</span></a>
        </li>
      <div class="sidebar-heading">
        Version
      </div>
      <li class="nav-item">
        <span class="nav-link">
          <i class="fas fa-laptop-code"></i>
          <span>1.4.2</span></span>
      </li>
    </ul>
    <!-- End of Sidebar -->

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">

        <!-- Main Content -->
        <div id="content">

            <!-- Topbar -->
            <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                <!-- Sidebar Toggle (Topbar) -->
                <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
                    <i class="fa fa-bars"></i>
                </button>

                <!-- Topbar Search -->
                <form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate
                    class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                    <div class="input-group">
                        <input name="icao" type="text" class="form-control bg-light border-0 small" placeholder="METAR"
                            aria-label="Search" aria-describedby="basic-addon2" [(ngModel)]="form.icao" required
                            #icao="ngModel">
                        <div class="input-group-append">
                            <button class="btn btn-primary" type="submit">
                                <i class="fas fa-search fa-sm"></i>
                            </button>
                        </div>
                    </div>
                </form>
                <div class="d-none d-sm-inline-block form-inline mr-auto ml-md-7 my-1 my-md-0 mw-100 navbar-search">
                    <div class="input-group">
                        {{metar.raw}}
                    </div>
                </div>

                <!-- Topbar Navbar -->
                <ul class="navbar-nav ml-auto">

                    <div class="topbar-divider d-none d-sm-block"></div>

                    <!-- Nav Item - User Information -->
                    <li class="nav-item dropdown no-arrow">
                        <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{username}}</span>
                        </a>
                        <!-- Dropdown - User Information -->
                        <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="userDropdown">
                            <!-- <a class="dropdown-item" href="#">
                                <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                Profile
                            </a>
                            <a class="dropdown-item" href="#">
                                <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                Settings
                            </a>
                            <a class="dropdown-item" href="#">
                                <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                Activity Log
                            </a> -->
                            <!-- <div class="dropdown-divider"></div> -->
                            <a (click)="logout()" class="dropdown-item" href="#" data-toggle="modal"
                                data-target="#logoutModal">
                                <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Logout
                            </a>
                        </div>
                    </li>

                </ul>

            </nav>
            <!-- End of Topbar -->

            <!-- Begin Page Content -->
            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div>
            <!-- /.container-fluid -->

        </div>
        <!-- End of Main Content -->

        <!-- Footer -->
        <footer class="sticky-footer bg-white">
            <div class="container my-auto">
                <div class="copyright text-center my-auto">
                    <span>Copyright &copy; Intisia VA Manager 2021</span>
                </div>
            </div>
        </footer>
        <!-- End of Footer -->

    </div>
    <!-- End of Content Wrapper -->

</div>
<!-- End of Page Wrapper -->

<!-- Scroll to Top Button-->
<a class="scroll-to-top rounded" href="#page-top">
    <i class="fas fa-angle-up"></i>
</a>

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {StatisticsPer} from "../models/statistics-per.model";

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(
    private http: HttpClient,
    @Inject('BASE_API_URL') private baseUrl: string
  ) {}

  getStatisticsPerSomething() {
    return this.http
      .get<StatisticsPer>(this.baseUrl + '/statistics/something');
  }

  getStatsForUser(username: string) {
    var url = this.baseUrl + '/statistics/user';
    if (username != null && username != '') {
      url = this.baseUrl + '/statistics/user?username=' + username;
    }
    return this.http
      .get<StatisticsPer>(url);
  }
}
